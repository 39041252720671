import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FC, PropsWithChildren } from "react";
import { localStorageKeys } from "../../../utils/storageKeys";

const karlFarmBaseUrl = process.env.NEXT_PUBLIC_KARL_FARM_BASE_URL;
const karlFarmConsumerId = process.env.NEXT_PUBLIC_KARL_FARM_CONSUMER_ID;
if (karlFarmBaseUrl == null) {
  throw new Error("NEXT_PUBLIC_KARL_FARM_BASE_URL is not set.");
}
if (karlFarmConsumerId == null) {
  throw new Error("NEXT_PUBLIC_KARL_FARM_CONSUMER_ID is not set.");
}

const getCommonHeaders = () => {
  const rawEnterpriseId = localStorage.getItem(localStorageKeys.enterpriseId);
  const rawApiKey = localStorage.getItem(localStorageKeys.apiKey);
  const parsedEnterpriseId =
    rawEnterpriseId == null ? null : JSON.parse(rawEnterpriseId);
  const parsedApiKey = rawApiKey == null ? null : JSON.parse(rawApiKey);

  const headers = {
    "consumer-id": karlFarmConsumerId,
    ...(typeof parsedEnterpriseId === "string"
      ? { "enterprise-id": parsedEnterpriseId }
      : {}),
    ...(typeof parsedApiKey === "string" ? { "x-api-key": parsedApiKey } : {}),
  };

  return headers;
};

export type KarlRequestOptions = Omit<RequestInit, "method" | "body">;

export const karlFarmDelete = async <TResponse = unknown,>(
  path: string,
  body?: Record<string, unknown>,
  options: KarlRequestOptions = {}
): Promise<TResponse> => {
  const { headers, ...restOptions } = options;
  const response = await fetch(`${karlFarmBaseUrl}${path}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...getCommonHeaders(),
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
    ...restOptions,
  });
  return response.json();
};

export const karlFarmPost = async <TResponse = unknown,>(
  path: string,
  body: Record<string, unknown>,
  options: KarlRequestOptions = {}
): Promise<TResponse> => {
  const { headers, ...restOptions } = options;
  const response = await fetch(`${karlFarmBaseUrl}${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getCommonHeaders(),
      ...headers,
    },
    body: JSON.stringify(body),
    ...restOptions,
  });
  return response.json();
};

export const karlFarmGet = async <TResponse = unknown,>(
  path: string
): Promise<TResponse> => {
  const response = await fetch(`${karlFarmBaseUrl}${path}`, {
    method: "GET",
    headers: {
      ...getCommonHeaders(),
    },
  });
  return response.json();
};

export const karlFarmQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        if (typeof queryKey[0] !== "string") {
          throw new Error(
            "Invalid query key. Expected first element to be a string."
          );
        }
        const path = queryKey[0];
        return karlFarmGet(path);
      },
    },
  },
});

type KarlFarmQueryClientProviderProps = PropsWithChildren;
export const KarlFarmQueryClientProvider: FC<
  KarlFarmQueryClientProviderProps
> = ({ children }) => {
  return (
    <QueryClientProvider client={karlFarmQueryClient}>
      {children}
    </QueryClientProvider>
  );
};

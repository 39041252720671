import { useLocalStorageValue } from "@react-hookz/web";
import { useEffect } from "react";
import { useApiKey, useEnterpriseId } from "../hooks/useAuthGuard";
import { localStorageKeys } from "../utils/storageKeys";

export const LocalStorageApiKeySync = () => {
  const enterpriseId = useEnterpriseId();
  const apiKey = useApiKey();
  const { set: setEnterpriseId } = useLocalStorageValue<string>(
    localStorageKeys.enterpriseId
  );
  const { set: setApiKey } = useLocalStorageValue<string>(
    localStorageKeys.apiKey
  );

  useEffect(() => {
    if (enterpriseId) {
      setEnterpriseId(enterpriseId);
    }
    if (apiKey) {
      setApiKey(apiKey);
    }
  }, [apiKey, enterpriseId, setApiKey, setEnterpriseId]);

  return null;
};

import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { MantineProvider } from "@mantine/core";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MotionConfig } from "framer-motion";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import { DayjsLocaleSync } from "../components/DayjsLocaleSync";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { LocalStorageApiKeySync } from "../components/LocalStorageSync";
import { KarlFarmQueryClientProvider } from "../hooks/api/karl-farm/queryClient";
import { transitionWithBounce } from "../utils/transitions";

const chakraTheme = extendTheme(
  withDefaultColorScheme({ colorScheme: "green" })
);

const CustomApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) => {
  return (
    <ErrorBoundary>
      <SessionProvider session={session}>
        <KarlFarmQueryClientProvider>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colorScheme: "light",
              components: {
                Tooltip: { defaultProps: { withinPortal: true } },
                MultiSelect: { defaultProps: { withinPortal: true } },
              },
              primaryColor: "green",
            }}
          >
            <ChakraProvider theme={chakraTheme}>
              <MotionConfig transition={transitionWithBounce}>
                <DayjsLocaleSync />
                <LocalStorageApiKeySync />
                <Component {...pageProps} />
              </MotionConfig>
            </ChakraProvider>
          </MantineProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </KarlFarmQueryClientProvider>
      </SessionProvider>
    </ErrorBoundary>
  );
};

export default CustomApp;

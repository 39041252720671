import * as dayjs from "dayjs";
import "dayjs/locale/da";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";
import { FC } from "react";
import { useLocale } from "../hooks/useLocale";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export const DayjsLocaleSync: FC = () => {
  const { locale } = useLocale();
  dayjs.locale(locale);
  return null;
};

import React from "react";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
    if (process.env.NODE_ENV === "development") {
      throw error;
    }
  }

  render() {
    return this.props.children;
  }
}

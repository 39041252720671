export const defaultAnimationSpeedMs = 300;

export const transitionWithBounce = {
  type: "spring",
  damping: 35,
  stiffness: 500,
};

export const transitionWithoutBounce = {
  type: "spring",
  damping: 40,
  stiffness: 500,
};

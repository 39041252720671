import { useLocalStorageValue, useSessionStorageValue } from "@react-hookz/web";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { localStorageKeys, sessionStorageKeys } from "../utils/storageKeys";
import { useSession } from "./useSession";

/**
 * @returns `null` if loading, `string | undefined` otherwise
 */
export const useUserId = (): string | null | undefined => {
  const { data: sessionData, status } = useSession();
  const userId = sessionData?.token?.sub;
  return status === "loading" ? null : userId;
};

/**
 * @returns `null` if loading, `string | undefined` otherwise
 */
export const useUsername = (): string | null | undefined => {
  const { data: sessionData, status } = useSession();
  const username = sessionData?.token?.profile?.["cognito:username"];
  return status === "loading" ? null : username;
};

export const USER_TYPE_ADMIN = "0";
export const USER_TYPE_USER = "3";
/**
 * @returns `null` if loading, `string | undefined` otherwise
 */
export const useUserType = (): string | null | undefined => {
  const { data: sessionData, status } = useSession();
  const username = sessionData?.token?.profile?.["custom:user_type"]; // "0" = admin, "3" = user
  return status === "loading" ? null : username;
};

/**
 * @returns `null` if loading, `string | undefined` otherwise
 */
export const useEmail = (): string | null | undefined => {
  const { data: sessionData, status } = useSession();
  const email = sessionData?.token?.email;
  return status === "loading" ? null : email;
};

/**
 * @returns `null` if loading, `string | undefined` otherwise
 */
export const useEnterpriseId = (): string | null | undefined => {
  const { data: sessionData, status } = useSession();
  const enterpriseId = sessionData?.token?.profile?.preferred_username;
  const { value: sessionStorageAdminOverrideEnterpriseId } =
    useSessionStorageValue<string>(
      sessionStorageKeys.adminOverrideEnterpriseId,
      { initializeWithValue: false }
    );
  const { value: localStorageEnterpriseId } = useLocalStorageValue<string>(
    localStorageKeys.enterpriseId,
    { initializeWithValue: false }
  );
  if (process.env.NEXT_PUBLIC_KARL_ENTERPRISE_ID_OVERRIDE) {
    return process.env.NEXT_PUBLIC_KARL_ENTERPRISE_ID_OVERRIDE;
  }
  if (status === "loading") {
    return null;
  } else if (
    typeof sessionStorageAdminOverrideEnterpriseId === "string" &&
    sessionStorageAdminOverrideEnterpriseId !== ""
  ) {
    return sessionStorageAdminOverrideEnterpriseId;
  } else if (enterpriseId) {
    return enterpriseId;
  } else if (typeof localStorageEnterpriseId === "string") {
    return localStorageEnterpriseId;
  } else {
    return undefined;
  }
};

/**
 * Gets the API key from the auth session's `custom:ent_api_key2`. Falls back to the api key in local storage.
 *
 * @returns `null` if loading, `string | undefined` otherwise
 */
export const useApiKey = (): string | null | undefined => {
  const { data: sessionData, status } = useSession();
  const apiKey = sessionData?.token?.profile?.["custom:ent_api_key2"];
  const { value: localStorageApiKey } = useLocalStorageValue<string>(
    localStorageKeys.apiKey
  );
  if (process.env.NEXT_PUBLIC_KARL_ENTERPRISE_API_KEY_OVERRIDE) {
    return process.env.NEXT_PUBLIC_KARL_ENTERPRISE_API_KEY_OVERRIDE;
  }
  if (status === "loading") {
    return null;
  } else if (apiKey) {
    return apiKey;
  } else if (typeof localStorageApiKey === "string") {
    return localStorageApiKey;
  } else {
    return undefined;
  }
};

export const useUsernameAuthGuard = () => {
  const router = useRouter();
  const username = useUsername();

  useEffect(() => {
    if (username !== null && !username) {
      router.push("/");
    }
  }, [username, router]);
};

export const useAuthGuard = () => {
  const router = useRouter();
  const username = useUsername();
  const enterpriseId = useEnterpriseId();
  const apiKey = useApiKey();

  useEffect(() => {
    if (username !== null && enterpriseId !== null) {
      if (!username && router.pathname !== "/") {
        router.push("/");
      } else if (!enterpriseId && router.pathname !== "/create-enterprise") {
        router.push("/create-enterprise");
      } else if (!apiKey && router.pathname !== "/create-enterprise") {
        router.push("/create-enterprise");
      }
    }
  }, [username, enterpriseId, apiKey, router]);
};

import { useRouter } from "next/router";

type LanguageCode = "en" | "da" | "es" | "fr";

type Locale = {
  locale: LanguageCode;
  intlLocale: Intl.Locale;
};

const defaultLocale = "en"; // Make sure this matches the default locale in next.config.js
const locales: Record<LanguageCode, Locale> = {
  en: {
    locale: "en",
    intlLocale: new Intl.Locale("en-US"),
  },
  da: {
    locale: "da",
    intlLocale: new Intl.Locale("da-DK"),
  },
  es: {
    locale: "es",
    intlLocale: new Intl.Locale("es-ES"),
  },
  fr: {
    locale: "fr",
    intlLocale: new Intl.Locale("fr-FR"),
  },
};

export const useLocale = (): Locale => {
  const { locale } = useRouter();

  if (locale != null && locale in locales) {
    const typedLocale = locale as keyof typeof locales;
    return locales[typedLocale];
  }

  return locales[defaultLocale];
};

import { useSession as nextAuthUseSession } from "next-auth/react";

type UseSessionReturnType =
  | { data: undefined; status: "loading" }
  | { data: KarlSession; status: "authenticated" };
export const useSession = (): UseSessionReturnType => {
  return nextAuthUseSession() as unknown as UseSessionReturnType;
};

export interface KarlSession {
  expires: string;
  token: KarlToken;
  user: User;
}

export interface KarlToken {
  account: Account;
  email: string;
  exp: number;
  iat: number;
  jti: string;
  profile: Profile;
  sub: string;
  user: TokenUser;
}

interface Account {
  access_token: string;
  expires_at: number;
  id_token: string;
  provider: string;
  providerAccountId: string;
  refresh_token: string;
  token_type: string;
  type: string;
}

interface Profile {
  "cognito:username": string;
  "custom:ent_api_key2": string;
  "custom:user_type": string;
  preferred_username: string;
  at_hash: string;
  aud: string;
  auth_time: number;
  email_verified: boolean;
  email: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  sub: string;
  token_use: string;
}

interface TokenUser {
  email: string;
  id: string;
}

interface User {
  email: string;
}

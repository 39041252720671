export const localStorageKeys = {
  enterpriseId: "karl-enterpriseId",
  apiKey: "karl-apiKey",
  systemOfMeasurement: "karl-systemOfMeasurement",
  fieldColoring: "karl-fieldColoring",
  irrigationColoring: "karl-irrigationColoring",
};

export const sessionStorageKeys = {
  mapStyle: "karl-map-mapStyle",
  mapState: "karl-map-mapState",
  mapFilterSelectedFieldGroups: "karl-map-filter-selectedFieldGroups",
  mapFieldDrawerHideCompletedTasks: "karl-map-fieldDrawer-hideCompletedTasks",
  mapAddFieldMode: "karl-map-addFieldMode",
  calendarStartCalendarOnSelectedDate:
    "karl-calendar-startCalendarOnSelectedDate",
  calendarColorTasksPerAssociatedUsers:
    "karl-calendar-colorTasksPerAssociatedUsers",
  calendarShowHeatMaps: "karl-calendar-showHeatMaps",
  calendarSelectedDate: "karl-calendar-selectedDate",
  calendarFiltersFieldGroups: "karl-calendar-filters-fieldGroups",
  calendarFiltersTasks: "karl-calendar-filters-tasks",
  calendarFiltersFields: "karl-calendar-filters-fields",
  calendarFiltersUsers: "karl-calendar-filters-users",
  calendarFiltersMachine: "karl-calendar-filters-machine",
  adminOverrideEnterpriseId: "karl-admin-override-enterpriseId",
};

export const clearLocalStorage = () => {
  Object.values(localStorageKeys).forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const clearSessionStorage = () => {
  Object.values(sessionStorageKeys).forEach((key) => {
    sessionStorage.removeItem(key);
  });
};
